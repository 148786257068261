<template>
  
  <div>
    
    <b-card no-body>
      <b-card-header>
        
            <div>
              <b-card-title class="mb-1">
                Demo Chart
              </b-card-title>
              
            </div>
            <!--/ title and subtitle -->

            <!-- datepicker -->
            <div class="d-flex align-items-center">
              <feather-icon
                icon="CalendarIcon"
                size="32"
              />
              
              <flat-pickr
                v-model="sr_range"
                :config="srconfig"
                class="form-control flat-picker bg-transparent border-0 shadow-none"
                placeholder="DD MMM YYYY"
                style="width:200%"
                @on-change="noOfCustomForm()"
              />

            </div>

        <!-- datepicker -->
      </b-card-header>
      
      
      <b-card-body>
        
        <vue-apex-charts type="area" height="350" :options="chartOptions" :series="series"></vue-apex-charts>

      </b-card-body>
    </b-card>
      

   </div> 
  
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BDropdown, BDropdownItem,BCardSubTitle } from 'bootstrap-vue'

//import { getUserData } from '@/auth/utils'
import { GET_API,POST_API } from "../../store/actions.type"
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import flatPickr from 'vue-flatpickr-component'
import moment from "moment-timezone";
import ChartjsComponentBarChart from './charts-components/ChartjsComponentBarChart.vue'
import Bus from "../../event-bus";
import vSelect from 'vue-select'

export default {
  components: {
    BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,VueApexCharts,BDropdown, BDropdownItem,flatPickr,BCardSubTitle,ChartjsComponentBarChart,vSelect

  },
  data() {
    return {
      
      //3rd Graph
      
      srconfig:{ 
        mode: 'range',
        dateFormat: 'd M Y',
        defaultDate: [
          moment.utc().subtract(7,'days').format('DD MMM YYYY'),
          moment.utc().format('DD MMM YYYY')
        ]
      },

      sr_range:moment.utc().subtract(7,'days').format('DD MMM YYYY') +' to '+moment.utc().format('DD MMM YYYY'),
      series: [{
      name: "Series 1",
      data: [
          [1486684800000, 34], 
          [1486771200000, 43], 
          [1486857600000, 31] , 
          [1486944000000, 43], 
          [1487030400000, 33], 
          [1487116800000, 52]
        ]
      }],
      chartOptions: {
           
        chart: {
          type: 'area',
          stacked: false,
          height: 350,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: []
            },
            autoSelected: 'zoom' 
          },
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
        },
        
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100]
          },
        },
        xaxis: {
          type: 'datetime',
        },
        tooltip: {
          shared: true
        }
           
      },
      
      
      
      
      
    }
  },
  methods:{
    
    
  },
  mounted() {
    
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
