<template>
	<div>
		<b-card no-body>
			<b-card-header>
				<div>
					<b-card-title class="mb-1">
						Synchronized Chart Demo
					</b-card-title>
				</div>
			</b-card-header>
			{{ series }}
			<b-card-body>
				<vue-apex-charts type="area" height="350" :options="chartOptions" :series="series"></vue-apex-charts>
			</b-card-body>

		</b-card>
	</div> 
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BDropdown, BDropdownItem,BCardSubTitle } from 'bootstrap-vue'

//import { getUserData } from '@/auth/utils'
import { GET_API,POST_API } from "../../store/actions.type"
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import flatPickr from 'vue-flatpickr-component'
import moment from "moment-timezone";
import ChartjsComponentBarChart from './charts-components/ChartjsComponentBarChart.vue'
import Bus from "../../event-bus";
import vSelect from 'vue-select'

export default {
	components: {
		BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,VueApexCharts,BDropdown, BDropdownItem,flatPickr,BCardSubTitle,ChartjsComponentBarChart,vSelect
	},
	data() {
		return {
			series: [{
				name: 'XYZ MOTORS',
				data: this.generateDayWiseTimeSeries(new Date('01 Feb 2017').getTime(), 20, {
					min: 10,
					max: 60
				})
			}],
			chartOptions: {
				chart: {
					type: 'area',
					stacked: false,
					height: 350,
					zoom: {
						type: 'x',
						enabled: true,
						autoScaleYaxis: true
					},
					toolbar: {
						autoSelected: 'zoom'
					}
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 0,
				},
				title: {
					text: '',
					align: 'left'
				},
				fill: {
					type: 'gradient',
					gradient: {
						shadeIntensity: 1,
						inverseColors: false,
						opacityFrom: 0.5,
						opacityTo: 0,
						stops: [0, 90, 100]
					},
				},
				yaxis: {
					labels: {
						formatter: function (val) {
						return (val / 1000000).toFixed(0);
						},
					},
					title: {
						text: ''
					},
				},
				xaxis: {
					type: 'datetime',
				},
				tooltip: {
					shared: false,
					y: {
						formatter: function (val) {
						return (val / 1000000).toFixed(0)
						}
					}
				}
			},

		}
	},
	methods:{
		generateDayWiseTimeSeries(baseval, count, yrange) {
			var i = 0;
			var series = [];
			while (i < count) {
				var x = baseval;
				var y =
				Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

				series.push([x, y]);
				baseval += 86400000;
				i++;
			}
			return series;
		}
	},
	mounted() {
		
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
